import React from 'react'
import off1 from '../../assets/aboutimg/off1.webp'
const OfficeCard = ({country}) => {
    return (
        <div className='card7'>
            <img src={off1} alt="image" />
            <div className=''>
                <h5>{country}</h5>
                {/*<p>Lorem Ipsum is simply dummy text of the </p>*/}
                <div>
                    <p>9876543210</p>
                </div>
            </div>
        </div>
    )
}

export default OfficeCard