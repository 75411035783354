import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import samplepdf from "../Navbar/Copyrights/Legal Restrictions.pdf";
import samplepdf1 from "../Navbar/Copyrights/Privacy.pdf";
export const Footer = () => {
  const [value, setValue] = useState({});
  const [validation, setValidation] = useState({});
  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!value.Email) {
      validdateData.Email = "Please Enter Your Email Id";
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(validation)
    let inputvalue = {
      Email: value.Email,
    }

    let formdata = new FormData()
    for (let key in inputvalue) {
      formdata.append(key, inputvalue[key])
    }
    if (CheckValidation()) {
      await axios
        .post(
          "https://script.google.com/macros/s/AKfycbws4QR-Y5SMckzinJhPecTkjlSNznM72xp2LUjsn6_99j8ukjgWRVvcRcQFf_s66lnZ/exec",
          formdata
        )
        .then(() => {
          setValue({});
          e.target.reset();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
  }


  const setData = (e, key) => {
    setValue({ ...value, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  return (
    <div className="">
      <footer className="section bg-footer">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-3">
              <div className="">
                <h2 className="font-bold text-uppercase text-white">
                  Logo
                </h2>
                <p className="contact-info">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text
                </p>
              </div>
            </div> */}
            <div className="col-md-3">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white font-bold">
                  Quick links
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <a href="">Home </a>
                  </li>
                  <li>
                    <Link to="/Aboutus" onClick={() => window.scrollTo(0, 0)}>About Us</Link>
                  </li>
                  <li>
                    <Link to="/Services" onClick={() => window.scrollTo(0, 0)}>Services</Link>
                  </li>
                  <li>
                    <Link to="/Products" onClick={() => window.scrollTo(0, 0)}>Products</Link>
                  </li>

                  {/* <li>
                    <Link to="/Career" onClick={() => window.scrollTo(0, 0)}>Career</Link>
                  </li> */}
                  {/*<li>
                    <Link to="/Blog" onClick={() => window.scrollTo(0, 0)}>Blog</Link>
                </li>*/}
                  <li>
                    <Link to="/Contactus" onClick={() => window.scrollTo(0, 0)}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white font-bold">
                  What We Do?
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <Link to="/webdevelopment">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/mobiledevelopment">Mobile app Development</Link>
                  </li>
                  <li>
                    <Link to="/mvpdevelopment">MVP Development</Link>
                  </li>
                  {/* <li>
                  <Link to="/servicedetails">Game Development</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white font-bold">
                  JOIN US!
                </h6>
                <div className="my-4">
                  <form onSubmit={handleSubmit}>
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control footer-input"
                      name="Email"
                      onChange={(e) => {
                        setData(e.target.value, "Email");
                      }}

                    />
                    {validation.Email && (
                      <p className="validationmsg">{validation.Email}</p>
                    )}
                    <button type="submit" class="btn mt-4 btn-light">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6">
              <p>
                <a
                  href="mailto:techoptune@gmail.com"
                  className="contact-info text-decoration-none"
                >
                  <span>
                    <i class="fa-solid fa-envelope"></i>
                  </span>{" "}
                  <span>rshankar@optunetechnologies.com</span>
                </a>
              </p>
              <p className="mb-0">
                {" "}
                <a href="https://www.google.com/maps/place/Optune+Technologies+LLP/@13.0317529,80.2521733,17z/data=!3m1!4b1!4m6!3m5!1s0x3a5267ca74c7c613:0x9e1c9f622dc9ae3b!8m2!3d13.0317477!4d80.2547482!16s%2Fg%2F11kqckpwdy?entry=ttu" target="_blank" className="contact-info text-decoration-none">
                  <span>
                    <i class="fa-solid fa-location-dot"></i>
                  </span>{" "}
                  <span>
                    Optune Technologies LLP, Bawa Road,Alwarpet, Chennai - 600018
                  </span>
                </a>
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <ul className="list-inline">
                <li className="list-inline-item">
                  {/* <a href="#">
                    <i className="fab facebook footer-social-icon fa-facebook-f"></i>
                  </a> */}
                </li>
                <li className="list-inline-item">
                  {/* <a href="#">
                    <i className="fab twitter footer-social-icon fa-twitter"></i>
                  </a> */}
                </li>
                <li className="list-inline-item">
                  {/* <a href="#">
                    <i className="fab google footer-social-icon fa-google"></i>
                  </a> */}
                </li>
                <li className="list-inline-item">
                  {/* <a href="#">
                    <i className="fa-brands insta fa-instagram footer-social-icon"></i>
                  </a> */}
                </li>
              </ul>
            </div>
            {/* <div className="col-lg-6 col-md-6">
              <p>
                <a
                  href="mailto:techoptune@gmail.com"
                  className="contact-info text-decoration-none"
                >
                  <span>
                    <i class="fa-solid fa-envelope"></i>
                  </span>{" "}
                  <span>rshankar@optunetechnologies.com</span>
                </a>
              </p>
              <p className="mb-0">
                {" "}
                <a href="" className="contact-info text-decoration-none">
                  <span>
                    <i class="fa-solid fa-location-dot"></i>
                  </span>{" "}
                  <span>
                    Optune Technologies LLP, Bawa Road, Chennai - 600018
                  </span>
                </a>
              </p>
            </div> */}
          </div>
        </div>
         <div className="text-center mt-5">
          <p className="footer-alt mb-0 f-14">
            &copy;2024. Optune Technologies LLP <a href={samplepdf} target="_blank" rel='Legal Restrictions'> Legal Restrictions  </a> and terms of use applicable to this site. <a href={samplepdf1} target="_blank" rel='Privacy.pdf'>Privacy</a> 
          </p>
        </div> 
      </footer>
    </div>
  );
};
