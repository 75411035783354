import { useEffect } from 'react';
import './App.css';
import "../src/Css/Style.css";
import "../src/Css/Responsive.css";
import AOS from "aos";
import "aos/dist/aos.css";

import { Main } from './Main';
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
