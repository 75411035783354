import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/logo.webp";
import Cycle from "../../assets/images/cycle.webp";
import StarIcon from "../../assets/images/star-icon.webp";
import Secure from "../../assets/images/sec.webp";
import Mule from "../../assets/images/mul.webp";
import Web from "../../assets/images/web.webp";
import Bus from "../../assets/images/bus.webp";
import Clo from "../../assets/images/clo.webp";
import Mweb from "../../assets/images/mweb.webp";
import Cus from "../../assets/images/cus.webp";

export const Navbar = ({ seract, proact }) => {
  const ProductTabs = ["Overview", "Consumer", "Enterprise"];
  const [closedr, setClosedr] = useState(false)
  const [closedr2, setClosedr2] = useState(false)
  // const [proact2, setProact2] = useState("")
  // const [seract2, setSeract2] = useState("")
  const ServiceSubTabs = [
    {
      img:Secure,
      heading: "IT Security & Compliance",
      content: "Need data security & compliance?",
    },
    {
      img:Mule,
      heading: "MuleSoft support",
      content:
        "Our managed and consulting service model helps fill in the knowledge gap in IT teams.",
    },
    {
      img:Clo,
      heading: "Cloud Migration",
      content:
        "Our Cloud migration service helps clients to reduce IT costs and improving performance with security and convenience advantage too.",
    },
    {
      img:Cus,
      heading: "Custom Software Development",
      content: "At Optune Technologies, we specialize in crafting bespoke software solutions that align seamlessly with your business objectives. Our experienced team of developers leverages the latest technologies to deliver scalable, secure, and high-performance applications that give you a competitive edge.",
    },
  ];
  const SubProductTabs = [
    {
      img:Secure,
      heading: "IT Security & Compliance",
      content: "Our IT security compliance products are tools and solutions designed to help organizations meet and adhere to regulatory requirements and industry standards related to information security.",
    },
    // {
    //   heading: "MuleSoft support",
    //   content: "Our managed and consulting service model helps fill in the knowledge gap in IT teams.",
    // },
    // {
    //   heading: "Cloud Migration",
    //   content: "Our Cloud migration service helps clients to reduce IT costs and improving performance with security and convenience advantage too.",
    // },
    // {
    //   heading: "Custom Development",
    //   content: " Tailored food delivery solutions crafted to meet your unique business needs and scale effortlessly. ",
    // },
    // {
    //   heading: "Quality Analysis",
    //   content: " Ensure top-notch service with our comprehensive quality analysis. Evaluate every aspect from order accuracy to delivery speed to guarantee customer satisfaction.",
    // }
  ];
  const [selectedProductTabs, setSelectedProductTabs] = useState(
    ProductTabs[0]
  );
  const [selectSubserviceTabs, setSelectSubserviceTabs] = useState(
    ServiceSubTabs[0]
  );
  const [selectSubProductTabs, setSelectSubProductTabs] = useState(
    SubProductTabs[0]
  );

  useEffect(() => {
    // Get all dropdown items
    const dropdownItems = document.querySelectorAll(".service");
    // Remove 'active' class from all dropdown items
    dropdownItems.forEach((item) => {
      item.classList.remove("active");
    });

    // // Add 'active' class to the first dropdown item
    dropdownItems[0].classList.add("active");
  }, []);

  const ProductDetailsTabs = [
    {
      heading: "Food Aggregation",
      content:
        " Demo Description - t non deserunt ullamco est sit aliqua amet sint.",
    },
    {
      heading: "Food Aggregation",
      content:
        " Demo Description - t non deserunt ullamco est sit aliqua amet sint.",
    },
    {
      heading: "Food Aggregation",
      content:
        " Demo Description - t non deserunt ullamco est sit aliqua amet sint.",
    },
  ];

  return (
    <div>
      <div className="bg-light-nav-top">
        <div className="home-navbar">
          <nav className="navbar navbar-expand-lg navbar-light navbar-fixed-top p-0">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img className="logo-img" src={Logo} alt="" />
              </a>
              <button
                className="navbar-toggler my-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className={`collapse navbar-collapse justify-content-end lh-lg ${closedr2 ? "d-none" : ""}`}
                id="main_nav"
              >
                <ul className="navbar-nav p-3 p-md-0">
                  <li className="nav-item">
                    <NavLink className="nav-link " to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link " to="/AboutUs">
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown d-lg-block d-none ktm-mega-menu">
                    <a
                      // className="nav-link dropdown-toggle"
                      className={`nav-link dropdown-toggle ${seract}`}
                      href="#"
                      data-bs-toggle="dropdown"
                      id="navbarDropdownItem"
                    >
                      Services
                    </a>

                    <div className={`dropdown-menu mega-menu p-3 ${closedr ? "d-none" : ""}`}>
                      <div className="container mega-menu-inner">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="">
                              <div className="tabs border-b">
                                {ProductTabs.map((tab, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        selectedProductTabs === tab
                                          ? "tab service active dropdownitem"
                                          : "tab service"
                                      }
                                      onMouseOver={() => {
                                        setSelectedProductTabs(tab);
                                      }}
                                    >
                                      <span>{tab}</span>
                                      <span>
                                        <i class="fa-solid fa-arrow-right"></i>
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="padd-left serviceid">
                              {selectedProductTabs === "Overview" && (
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="ser-over">
                                      <h5 className="fw-bold">
                                        Services
                              </h5>
                                      <div className="row">
                                        <div className="">
                                          <p>
                                          We are dedicated to transforming your vision into reality. Partner with us to embark on a journey of innovation, efficiency, and digital success. Let's build the future together. 
                                          </p>
                                          <NavLink to="/Services">
                                            <button
                                              type="button"
                                              className="btn btn-primary contact-btn"
                                              onClick={() => {
                                                setClosedr(true)
                                                setTimeout(() => {
                                                  setClosedr(false)
                                                }, 1000)
                                                // setSeract("active")
                                              }}
                                            >
                                              <span className="me-2">Discover</span>{" "}
                                              <span>
                                                <i class="fa-solid fa-arrow-right"></i>
                                              </span>
                                            </button>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {selectedProductTabs === "Consumer" && (
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="ser-over">
                                      <h6 className="fw-bold">
                                        Food delivery and aggregation as a service
                                      </h6>
                                      <div className="row">
                                        <div className="">
                                          <p>
                                            Our food delivery platform is a robust and innovative business model that will transform the food delivery landscape in India
                                          </p>
                                          <NavLink
                                            to="/Services?id=servicepage"
                                            onClick={() => {
                                              setClosedr(true)
                                              setTimeout(() => {
                                                setClosedr(false)
                                              }, 1000)
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary contact-btn"
                                            >
                                              <span className="me-2">Discover</span>{" "}
                                              <span>
                                                <i class="fa-solid fa-arrow-right"></i>
                                              </span>
                                            </button>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {selectedProductTabs === "Enterprise" && (
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="drop-scroll">
                                      {ServiceSubTabs.map((tab, index) => {
                                        return (
                                          <div
                                            className={
                                              selectSubserviceTabs.heading ===
                                                tab.heading
                                                ? "bg-food-agr active "
                                                : "bg-food-agr "
                                            }
                                            key={index}
                                            onClick={() => {
                                              setSelectSubserviceTabs(tab);
                                              console.log(tab);
                                              console.log(selectSubserviceTabs);
                                            }}
                                          >
                                            <div className="d-flex justify-content-start ">
                                              <img
                                                className="cycle-style"
                                                src={tab.img}
                                                alt=""
                                              />
                                              <div>
                                                <h6 className="fw-bold">
                                                  {tab.heading}
                                                </h6>
                                                <p className="mb-0">
                                                  {tab.content}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <div className="col-lg-8">
                                    {selectSubserviceTabs.heading ===
                                      "IT Security & Compliance" && (
                                        <div className="row">
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Soc2 Type 2 Audit
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=Soc2Type2Audit"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2 mb-0">
                                                  SOC 2 controls enable
                                                  information security controls at
                                                  a service organization and the
                                                  audit reports are certified by a
                                                  licensed CPA firm
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      VAPT
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=VAPT"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2 mb-0">
                                                  Vulnerability Assessment and
                                                  Penetration Testing (VAPT)
                                                  services supports in assessing
                                                  the current status of the
                                                  security, identifying exact
                                                  flaws and advising a remedial
                                                  action plan to safeguard the
                                                  system.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {selectSubserviceTabs.heading ===
                                      "MuleSoft support" && (
                                        <div className="row">
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Managed Services
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=ManagedServices"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2 mb-0">
                                                  Optune’s Managed Services (MS)
                                                  offers and provides world class
                                                  24x7 support and maintenance
                                                  services to enterprises of
                                                  various verticals, tailored for
                                                  those with complex and
                                                  integrated MuleSoft
                                                  environments.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Consulting Services
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=ConsultingServices"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2 mb-0">
                                                  Our MuleSoft Consulting service
                                                  helps you to identify actionable
                                                  opportunities, plan and sustain
                                                  your integration strategies
                                                  enabling full leverage of the
                                                  MuleSoft platform.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {selectSubserviceTabs.heading ===
                                      "Cloud Migration" && (
                                        <div className="row">
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Kubernetes management
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=Kubernetesmanagement"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2">
                                                  Kubernetes is used to create
                                                  applications that are easy to
                                                  manage and deploy anywhere.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Object Storage
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=ObjectStorage"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2">
                                                  Object storage offers a
                                                  scalable, flexible, and
                                                  cost-effective solution for
                                                  managing large volumes of
                                                  unstructured data.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Global Content Delivery
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=GlobalContentDelivery"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2">
                                                  Our Global content delivery
                                                  model is the best in practice
                                                  and helps in the distribution
                                                  and delivery of digital content
                                                  on a worldwide scale,
                                                  facilitated by cloud-based
                                                  infrastructure and Content
                                                  Delivery Networks (CDNs).
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {selectSubserviceTabs.heading ===
                                      "Custom Development" && (
                                        <div className="row">
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Mobile Web App
                                                    </h6>
                                                    <NavLink
                                                      to="/mobiledevelopment"
                                                      className="mb-0 cursor-pointer"
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2">
                                                  Experts in developing customized mobile applications tailoring to your needs. Great experience in React Native, Flutter and many more technologies.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Web applications
                                                    </h6>
                                                    <NavLink
                                                      to="/webdevelopment"
                                                      className="mb-0 cursor-pointer"
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2">
                                                  Experts in developing custom web applications tailored to Client needs and requirements. User friendly web applications using technologies like React JS, Node JS and many more technologies
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown  d-lg-none  d-block">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="false"
                    >
                      Services
                    </a>

                    <ul className="dropdown-menu mob-dr-1">
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/Services"
                        >
                          Overview
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/Services"
                        >
                          Consumer
                        </NavLink>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="dropdown-toggle nav-link"
                          href="#"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                        >
                          Enterprise
                        </a>
                        <ul className="dropdown-menu mob-dr-1">
                          <li className="nav-item dropdown">
                            <a
                              className="dropdown-toggle nav-link"
                              href="#"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="false"
                            >
                              It Security & Compliance
                            </a>
                            <ul className="dropdown-menu mobnav">
                              <li>
                                <NavLink
                                  to="/Services?id=Soc2Type2Audit"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Soc2 Type 2 Audit
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/Services?id=VAPT"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  VAPT
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="dropdown-toggle nav-link"
                              href="#"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="false"
                            >
                              MuleSoft support
                            </a>
                            <ul className="dropdown-menu mobnav">
                              <li>
                                <NavLink
                                  to="/Services?id=ManagedServices"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Managed Services
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/Services?id=ConsultingServices"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Consulting Services
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="dropdown-toggle nav-link"
                              href="#"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="false"
                            >
                              Cloud Migration
                            </a>
                            <ul className="dropdown-menu mobnav">
                              <li>
                                <NavLink
                                  to="/Services?id=Kubernetesmanagement"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Kubernetes management
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/Services?id=ObjectStorage"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Object Storage
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/Services?id=GlobalContentDelivery"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Global Content Delivery
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="dropdown-toggle nav-link"
                              href="#"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="false"
                            >
                              Custom Development
                            </a>
                            <ul className="dropdown-menu mobnav">
                              <li>
                                <NavLink
                                  to="/mobiledevelopment"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Mobile Web App
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/webdevelopment"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Web applications
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown d-lg-block d-none ktm-mega-menu">
                    <a
                      className={`nav-link dropdown-toggle ${proact}`}
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      Products
                    </a>

                    <div className={`dropdown-menu mega-menu p-3 ${closedr ? "d-none" : ""}`}>
                      <div className="container mega-menu-inner">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="">
                              <div className="tabs border-b">
                                {ProductTabs.map((tab, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        selectedProductTabs === tab
                                          ? "tab active  "
                                          : "tab "
                                      }
                                      onMouseOver={() => {
                                        setSelectedProductTabs(tab);
                                      }}
                                    >
                                      <span>{tab}</span>
                                      <span>
                                        <i class="fa-solid fa-arrow-right"></i>
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="padd-left">
                              {selectedProductTabs === "Overview" && (
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="ser-over">
                                      <h5 className="fw-bold">
                                        Products
                                      </h5>
                                      <div className="row">
                                        <div className="">
                                          <p>
                                            Our product vision is grounded in a commitment to crafting innovative, reliable, and transformative software solutions that empower businesses and individuals alike. Our tenets are innovation at the core, User-Centric Design, Scalability and Adaptability, Realiablity and Performance,  Continuous Improvement and Updates, Interoperability and Integration, Environmental Sustainability and Customer Success.
                                          </p>
                                          <NavLink to="/Products">
                                            <button
                                              type="button"
                                              className="btn btn-primary contact-btn"
                                              onClick={() => {
                                                setClosedr(true)
                                                setTimeout(() => {
                                                  setClosedr(false)
                                                }, 1000)
                                              }}
                                            >
                                              <span className="me-2">Discover</span>{" "}
                                              <span>
                                                <i class="fa-solid fa-arrow-right"></i>
                                              </span>
                                            </button>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {selectedProductTabs === "Consumer" && (
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="ser-over">
                                      <h5 className="fw-bold">
                                        Food delivery and aggregation platform
                                      </h5>
                                      <div className="row">
                                        <div className="">
                                          <p>
                                            Our food delivery platform is a robust and innovative business model that will transform the food delivery landscape in India
                                          </p>
                                          <NavLink to="/Services?id=servicepage">
                                            <button
                                              type="button"
                                              className="btn btn-primary contact-btn"
                                              onClick={() => {
                                                setClosedr(true)
                                                setTimeout(() => {
                                                  setClosedr(false)
                                                }, 1000)
                                              }}
                                            >
                                              <span className="me-2">Discover</span>{" "}
                                              <span>
                                                <i class="fa-solid fa-arrow-right"></i>
                                              </span>
                                            </button>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {selectedProductTabs === "Enterprise" && (
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div
                                    // className="drop-scroll"
                                    >
                                      {SubProductTabs.map((tab, index) => {
                                        return (
                                          <div
                                            className={
                                              selectSubProductTabs.heading ===
                                                tab.heading
                                                ? "bg-food-agr active "
                                                : "bg-food-agr "
                                            }
                                            key={index}
                                            onClick={() => {
                                              setSelectSubProductTabs(tab);
                                            }}
                                          >
                                            <div className="d-flex justify-content-start ">
                                              <img
                                                className="cycle-style"
                                                src={tab.img}
                                                alt=""
                                              />
                                              <div>
                                                <h6 className="fw-bold">
                                                  {tab.heading}
                                                </h6>
                                                <p>{tab.content}</p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <div className="col-lg-8">
                                    {selectSubProductTabs.heading ===
                                      "IT Security & Compliance" && (
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1">
                                                      {/* Features */}
                                                      Secure Your Business
                                                    </h6>
                                                    <NavLink
                                                      to="/Products?id=productfeatures"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text">
                                                  Our products are for businesses aiming to protect sensitive data, maintain the confidentiality, integrity, and availability of information, and demonstrate compliance with various security frameworks.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {/* {selectSubProductTabs.heading ===
                                      "Quality Analysis" && (
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1">
                                                      Quality Assurance
                                                    </h6>
                                                    <NavLink
                                                      to="/qualityanalysis"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text">
                                                  Guarantee top-notch service and customer satisfaction with our rigorous quality assurance protocols. We meticulously monitor every aspect of the delivery process to ensure consistency and excellence.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {selectSubProductTabs.heading ===
                                      "MuleSoft support" && (
                                        <div className="row">
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Managed Services
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=ManagedServices"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2 mb-0">
                                                  Optune’s Managed Services (MS)
                                                  offers and provides world class
                                                  24x7 support and maintenance
                                                  services to enterprises of
                                                  various verticals, tailored for
                                                  those with complex and
                                                  integrated MuleSoft
                                                  environments.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Consulting Services
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=ConsultingServices"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2 mb-0">
                                                  Our MuleSoft Consulting service
                                                  helps you to identify actionable
                                                  opportunities, plan and sustain
                                                  your integration strategies
                                                  enabling full leverage of the
                                                  MuleSoft platform.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {selectSubProductTabs.heading ===
                                      "Cloud Migration" && (
                                        <div className="row">
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Kubernetes management
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=Kubernetesmanagement"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2">
                                                  Kubernetes is used to create
                                                  applications that are easy to
                                                  manage and deploy anywhere.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Object Storage
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=ObjectStorage"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2">
                                                  Object storage offers a
                                                  scalable, flexible, and
                                                  cost-effective solution for
                                                  managing large volumes of
                                                  unstructured data.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 mb-2">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1 font-bold">
                                                      Global Content Delivery
                                                    </h6>
                                                    <NavLink
                                                      to="/Services?id=GlobalContentDelivery"
                                                      className="mb-0 cursor-pointer"
                                                      onClick={() => {
                                                        setClosedr(true)
                                                        setTimeout(() => {
                                                          setClosedr(false)
                                                        }, 1000)
                                                      }}
                                                    >
                                                      Browse all{" "}
                                                      <i class="fa-solid fa-arrow-right"></i>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text mt-2">
                                                  Our Global content delivery
                                                  model is the best in practice
                                                  and helps in the distribution
                                                  and delivery of digital content
                                                  on a worldwide scale,
                                                  facilitated by cloud-based
                                                  infrastructure and Content
                                                  Delivery Networks (CDNs).
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {selectSubProductTabs.heading ===
                                      "Custom Development" && (
                                        <div className="row gy-3">
                                          <div className="col-lg-6">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1">
                                                      MVP Development
                                                    </h6>
                                                    <NavLink to="/mvpdevelopment">
                                                      <p className="mb-0 cursor-pointer">
                                                        Browse all{" "}
                                                        <i class="fa-solid fa-arrow-right"></i>
                                                      </p>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text">
                                                  Embark on your food delivery MVP journey with our expert development team. Build a streamlined platform to connect hungry customers with local restaurants efficiently.
                                                </p>
                                              </div>
                                            </div>

                                          </div>
                                          <div className="col-lg-6">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1">
                                                      Web Application Development
                                                    </h6>
                                                    <NavLink to="/webdevelopment">
                                                      <p className="mb-0 cursor-pointer">
                                                        Browse all{" "}
                                                        <i class="fa-solid fa-arrow-right"></i>
                                                      </p>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text">
                                                  Revolutionize food delivery with our web application development services. Create a seamless platform connecting users and restaurants for convenient ordering and delivery.
                                                </p>
                                              </div>
                                            </div>

                                          </div>
                                          <div className="col-lg-6">
                                            <div class="card">
                                              <div class="card-body">
                                                <div className="d-flex align-items-center">
                                                  <div className="bg-star-icon">
                                                    <img
                                                      className="inner-icon"
                                                      src={StarIcon}
                                                    />
                                                  </div>

                                                  <div>
                                                    <h6 class="card-title mb-1">
                                                      Mobile Application Development
                                                    </h6>
                                                    <NavLink to="/mobiledevelopment">
                                                      <p className="mb-0 cursor-pointer">
                                                        Browse all{" "}
                                                        <i class="fa-solid fa-arrow-right"></i>
                                                      </p>
                                                    </NavLink>
                                                  </div>
                                                </div>
                                                <p class="card-text">
                                                  Elevate food delivery experiences with our mobile app development expertise. Seamlessly connect users to their favorite restaurants for easy ordering and swift delivery.
                                                </p>
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      )} */}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown  d-lg-none d-block">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="false"
                    >
                      Products
                    </a>

                    <ul className="dropdown-menu mob-dr-1">
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/Products"
                        >
                          Overview
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/products"
                        >
                          Consumer
                        </NavLink>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="dropdown-toggle nav-link"
                          href="#"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                        >
                          Enterprise
                        </a>
                        <ul className="dropdown-menu mob-dr-1">
                          <li className="nav-item dropdown">
                            <a
                              className="dropdown-toggle nav-link"
                              href="#"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="false"
                            >
                              It Security & Compliance
                            </a>
                            <ul className="dropdown-menu mobnav mob-dr-1">
                              <li>
                                <NavLink
                                  to="/Products?id=productfeatures"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Features
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          {/* <li className="nav-item dropdown">
                            <a
                              className="dropdown-toggle nav-link"
                              href="#"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="false"
                            >
                              MuleSoft support
                            </a>
                            <ul className="dropdown-menu mobnav">
                              <li>
                                <NavLink
                                  to="/Services?id=ManagedServices"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Managed Services
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/Services?id=ConsultingServices"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Consulting Services
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="dropdown-toggle nav-link"
                              href="#"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="false"
                            >
                              Cloud Migration
                            </a>
                            <ul className="dropdown-menu mobnav">
                              <li>
                                <NavLink
                                  to="/Services?id=Kubernetesmanagement"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Kubernetes management
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/Services?id=ObjectStorage"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Object Storage
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/Services?id=GlobalContentDelivery"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Global Content Delivery
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="dropdown-toggle nav-link"
                              href="#"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="false"
                            >
                              Custom Development
                            </a>
                            <ul className="dropdown-menu mobnav">
                              <li>
                                <NavLink
                                  to="/mvpdevelopment"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  MVP Development
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/mobiledevelopment"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Mobile Web App
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/webdevelopment"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Web applications
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="dropdown-toggle nav-link"
                              href="#"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="false"
                            >
                              Quality Analysis
                            </a>
                            <ul className="dropdown-menu mobnav">
                              <li>
                                <NavLink
                                  to="/qualityanalysis"
                                  className="mb-0 cursor-pointer"
                                  onClick={() => {
                                    setClosedr2(true)
                                    setTimeout(() => {
                                      setClosedr2(false)
                                    }, 1000)
                                  }}
                                >
                                  Quality Assurance
                                </NavLink>
                              </li>
                            </ul>
                          </li> */}
                        </ul>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink className="nav-link " to="/Career">
                      Career
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <NavLink className="nav-link " to="/Blog">
                      Blog
                    </NavLink>
                  </li> */}
                  <li className="nav-item my-auto">
                    <NavLink to="/Contactus">
                      <button
                        type="button"
                        className="btn btn-primary contact-btn"
                      >
                        Contact us
                      </button>
                    </NavLink>
                  </li>

                  {/* <li className="nav-item dropdown d-lg-none d-block">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      Pages{" "}
                    </a>

                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          About Us{" "}
                        </a>
                      </li>

                      <li>
                        <a className="dropdown-item" href="#">
                          {" "}
                          Dropdown Content
                          <strong className="float-end">&raquo;</strong>{" "}
                        </a>
                        <div className="submenu dropdown-menu mega-submenu p-3">
                          <span>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.
                          </span>
                        </div>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Login{" "}
                        </a>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="nav-item dropdown d-lg-none d-block">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      {" "}
                      Treeview menu{" "}
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          {" "}
                          Dropdown item 1{" "}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          {" "}
                          Dropdown item 2 <strong className="float-end">
                            »
                          </strong>{" "}
                        </a>

                        <ul className="submenu dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">
                              Submenu item 1
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Submenu item 3{" "}
                              <strong className="float-end">»</strong>{" "}
                            </a>

                            <ul className="submenu dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Multi level 1
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Multi level 2
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Submenu item 4
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
