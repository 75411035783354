import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from 'react'
import Customervoice from "./Customervoice";

const Carousal2 = () => {
    return (
        <div>
            <div className="container">
                <div className=''>
                    <div className='align' data-aos="fade-up" data-aos-delay="300">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='content-head'>
                                    <h5 className='hr-lines font-bold'>happy clients</h5>
                                </div>
                            </div>
                        </div>
                        <h1 className="font-bold">What Our Client Say’s</h1>
                        {/*<p className='hm-pr-w2'>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                    </p>*/}
                    </div>
                </div>
            </div>
            <div className="carousal1">
                <div className="container">
                    <div className="row homecsl">
                        {/* <OwlCarousel
                            className="owl-theme"
                            loop
                            autoplay={false}
                            items="2"
                            dots={false}
                            smartSpeed={2000}
                            nav={true}
                            margin={20}
                            center={true}
                            autoplayTimeout={5000}
                            responsive={{
                                360: {
                                    items: "1",
                                },
                                414: {
                                    items: "1",
                                },
                                670: {
                                    items: "2",
                                },
                                992: {
                                    items: "2",
                                },
                                1200: {
                                    items: "3",
                                },
                            }}
                        >
                            <Customervoice />
                            <Customervoice />
                            <Customervoice />
                        </OwlCarousel> */}
                        <Customervoice />
                        <Customervoice />
                        {/* <Customervoice /> */}
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}

export default Carousal2