import React from 'react'
import profile from '../../assets/Cardimages/profile.webp'

const Customervoice = () => {
    return (
        <div className='comment-1'>
            {/* <div className='quote'>
                <i class="fa-solid fa-quote-right"></i>
            </div> */}
            <div className='customervoice'>
                <div>
                    <p>“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.”</p>
                </div>
                <div>
                    <div className='profile'>
                        <div>
                            <img src={profile} />
                        </div>
                        <div>
                            <h6 className='font-bold'>Steve Rogers</h6>
                            <p>CEO Of Shield</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Customervoice