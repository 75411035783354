import React from 'react'
import four from '../../assets/aboutimg/four.webp'
import Light from '../../assets/aboutimg/logo1.webp'
import Star from '../../assets/aboutimg/logo2.webp'
import people1 from '../../assets/aboutimg/people1.webp'
import people2 from '../../assets/aboutimg/people2.webp'
import people3 from '../../assets/aboutimg/people3.webp'
import Team from '../../assets/images/ourteam.webp'
import Profile from './Profile'
import Valuecard from './Valuecard'
import OfficeCard from './OfficeCard'
import { Link } from 'react-router-dom'

const AboutBanner = () => {
    return (
        <div>
            <div className=' aboutbanner' data-aos="fade-up" data-aos-delay="300">
                <h4 className='font-bold'>About Us</h4>
            </div>
            <div className='mt-4' data-aos="fade-up" data-aos-delay="300">
                <div className='container'>
                    <div className='row '>
                        <div className='col-lg-6'>
                            <img src={four} alt="image" />
                        </div>
                        <div className='col-lg-6 mt-3'>
                            <div className='content-head'>
                                <h5 className='hr-lines font-bold'>About us</h5>
                            </div>
                            <h1 className='font-bold'>One of the Fastest Way to Business Growth</h1>
                            <p>We provide IT based or enabled products and services focused on consumers and enterprises. We are thrilled with the excitement of customer delight when we make a consumer happy with an awesome product and gratified when we help enterprises navigate the challenges inherent in today’s business.</p>
                            <div className='vision'>
                                <div>
                                    <div>
                                        <img src={Light} />
                                    </div>
                                    <div>
                                        <h6 className='font-bold'>Our Vision</h6> 
                                        <p>At Optune, our vision is to be at the forefront of transformative technology, pioneering innovations that reshape industries and enhance the way people live, work, and connect. We envision a future where our software solutions empower organizations and individuals to achieve their full potential, driving positive change on a global scale.</p>
                                    </div>
                                </div>
                                {/* <div>
                                    <div>
                                        <img src={Star} />
                                    </div>
                                    <div>
                                        <h6 className='font-bold'>Our Goal</h6>
                                        <p>It is a long established fact that</p>
                                    </div>
                                </div>   */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container my-5'>
                <div className='leaders'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='content-head'>
                                <h5 className='hr-lines font-bold'>Team</h5>
                            </div>
                        </div>
                    </div>
                    <h2 className='font-bold ourteam'>Our Team</h2>
                    <img src={Team} alt='team' className='groupphoto' />
                    {/* <div className='aboutprofile'  data-aos="fade-up" data-aos-delay="300">
                        <Profile people={people1} />
                        <Profile people={people2} />
                        <Profile people={people3} />
                        <Profile people={people1} />
                    </div>
                    <div className='aboutprofile'  data-aos="fade-down" data-aos-delay="300">
                        <Profile people={people2} />
                        <Profile people={people3} />
                        <Profile people={people1} />
                        <Profile people={people2} />
                    </div> */}
                </div>
            </div>
            <div className='container'>
                <div className='valuecards'>
                    <div className='values' data-aos="fade-down" data-aos-delay="300">
                        <div>
                            <h6>Our Values</h6>
                            <h1>Guided by Integrity and Purpose</h1>
                            <p>At Optune, our values are the guiding principles that define who we are, how we operate, and the impact we aim to create in the world. Rooted in a commitment to excellence, integrity, and Collaboration, our values form the foundation of our company culture and inspire every aspect of our work.</p>
                        </div>
                    </div>
                    <div className='row' data-aos="fade-up" data-aos-delay="300">
                        <div className='col-lg-4 col-sm-6'>
                            <Valuecard title="Integrity" icon={<i class="fa-solid fa-puzzle-piece"></i>}/>
                            <p>Our commitment to integrity is not just a value but the cornerstone of our identity as a trusted software company. We operate with transparency, honesty, and a dedication to ethical business practices. Our integrity statement is a reflection of our unwavering commitment to our clients, employees, partners, and the broader community.</p>
                        </div>
                        <div className='col-lg-4 col-sm-6'>
                            <Valuecard title="Innovation" icon={<i class="fa-solid fa-rocket"></i>} />
                            <p>We believe the innovation begins with a culture that nurtures creativity. We encourage our team members to think beyond boundaries, challenge assumptions, and explore unconvential solutions. By fostering a culture of creativity, we empower our employees to unleash their full potential and drive meaningful innovation.</p>
                        </div>
                        <div className='col-lg-4 col-sm-6'>
                            <Valuecard title="Collaboration" icon={<i class="fa-solid fa-users-viewfinder"></i>} />
                            <p>Our convictions is that the synergy created by individuals working seamlessly together yields solutions that transcend the ordinary. Our Collaboration statement reflects our unwavering commitment to fostering a culture of teamwork, open communication, and shared success.</p>
                        </div>
                        {/* <div className='col-lg-4 col-sm-6'>
                            <Valuecard title="Customer-centric" icon={<i class="fa-solid fa-people-arrows"></i>} />
                        </div>
                        <div className='col-lg-4 col-sm-6'>
                            <Valuecard title="Sustainability" icon={<i class="fa-solid fa-recycle"></i>} />
                        </div>
                        <div className='col-lg-4 col-sm-6'>
                            <Valuecard title="Excellence" icon={<i class="fa-solid fa-star"></i>} />
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <div className='container mb-5'>
                <div className='row'>
                    <div className='col-lg-6'  data-aos="fade-up" data-aos-delay="300">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='content-head'>
                                    <h5 className='hr-lines font-bold'>Our mission Statement</h5>
                                </div>
                            </div>
                        </div>
                        <h1 className='font-bold'>Empowering the world through transformative technology solutions</h1>
                        <p>We are dedicated to empowering individuals, businesses, and communities by providing innovative and cutting-edge technology solutions that unlock new possibilities and drive positive change. Our mission is to make technology accessible, reliable, and impactful, enabling our customers to thrive in the digital era and shape a better future for all.
                            Our mission is to make technology accessible, reliable, and impactful, enabling our customers to thrive in the digital era and shape a better future for all.
                        </p>
                        <Link to="/Services" onClick={() => window.scrollTo(0, 0)} >
                            <button className='contact-btn'>Get Started</button>
                        </Link>
                    </div>
                    <div className='col-lg-6 mt-3'  data-aos="fade-up" data-aos-delay="300">
                        <img src={four} alt="image" className='image' />
                    </div>
                </div>
            </div> */}
            <div className='values '>
                <div className="mb-3" data-aos="fade-up" data-aos-delay="300">
                    <h6>Our Offices</h6>
                    <h1 className='font-bold'>Discover Our Global Network of Offices</h1>
                    <p>Experience our expansive network of offices across the world, connecting you to our exceptional services wherever you are.</p>
                </div>
            </div>
            <div className='container mb-5'>
                <div className='row office' data-aos="fade-up" data-aos-delay="300">
                    <OfficeCard country='United States of America'/>
                    <OfficeCard country='India' />
                    {/*<OfficeCard />*/}
                </div>
            </div>
        </div>
    )
}

export default AboutBanner