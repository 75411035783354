import { Link } from 'react-router-dom'
import React from 'react'
import SquareIcon from '../../assets/images/apps.webp'
import Game from '../../assets/images/game.webp'
import Mike from '../../assets/images/mike.webp'
import Mobile from '../../assets/images/mobile.webp'
import Stats from '../../assets/images/stats.webp'
import Discount from '../../assets/images/discount.webp'
import Rating from '../../assets/images/rating.webp'
import Fooddelivery from '../../assets/Cardimages/fooddelivery.webp'
import Epay from '../../assets/Cardimages/epay.webp'
import Podcast from '../../assets/Cardimages/podcast.webp'
import Service from '../../assets/Cardimages/service.webp'
import Ecom1 from '../../assets/Cardimages/ecom1.webp'
import Ecom2 from '../../assets/Cardimages/ecom2.webp'
import Ecom3 from '../../assets/Cardimages/ecom3.webp'
import Ecom4 from '../../assets/Cardimages/ecom4.webp'
import Team2 from '../../assets/Cardimages/team2.webp'

import Card2 from './Card2'
import Card3 from './Card3'
import Card4 from './Card4'
import Carousal from './Carousal'
import Carousal2 from './Carousal2'

export const HomeContent = () => {
  return (
    <div className='content' id="getstarted">
      <div>
        <Carousal />
      </div>
      <div className='client-content'>
        <div className='container'>
          <div className='row'>
            <div className='client col-md-8 col-lg-6' data-aos="fade-up" data-aos-delay="300">
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='content-head'>
                    <h5 className='hr-lines font-bold'>Services</h5>
                  </div>
                </div>
              </div>
              <h1 className='font-bold'>Our Services</h1>
              <p className='hm-pr-w'>
              We are a cutting-edge software company dedicated to providing unparalleled solutions tailored to meet your unique business needs. Our comprehensive suite of services is designed to empower your organization, streamline operations, and drive success in today's dynamic digital landscape.
              </p>
            </div>
            <div className='col-md-4 aboutbtn col-lg-6' data-aos="fade-up" data-aos-delay="300">
              <Link to="/Services" onClick={() => window.scrollTo(0, 0)} className='text-decoration-none'>
                <button className=' contact-btn'>

                  See More
                  <span className='rightarrow'>
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </button>
              </Link>

            </div>
          </div>
        </div>
      </div>
      <div className='cards'>
        <div className='container'>
          <div className='row '>
            <div className="col-lg-4 col-md-4 col-12 my-2" data-aos="fade-up" data-aos-delay="300">
              <div className='servicecard'>
                <div className='cardicon'>
                  <i class="fa-solid fa-globe"></i>
                </div>
                <div className='cardContent '>
                  <h5 className='font-bold'>Web Development</h5>
                  <p>Stay ahead in the online realm with our web application development services. Whether you need a robust <br></br>e-commerce platform, a responsive business portal, or a dynamic web presence, our experts design and develop web application that captivate users and elevate your online presence.</p>
                  
                  <br></br>
                  <div>
                  <p3 className='font-bold'>Know More</p3>
                   <Link to="/webdevelopment">
                    <button className='font-bold'>
                    <i class="fa-solid fa-chevron-right"></i>
                    </button>
                  </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 col-md-4 my-2" data-aos="fade-up" data-aos-delay="300">
              <div className='servicecard'>
                <div className='cardicon'>
                  <i class="fa-solid fa-mobile-screen"></i>
                </div>
                <div className='cardContent '>
                  <h5 className='font-bold'>Mobile Development</h5>
                  <p>Leverage the booming mobile app development services. From iOS to Android and Cross-platform solutions, we create intuitive and feature-rich mobile applications that enhance user engagement and deliver a seamless experience across devices.</p>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div>
                  <p3 className='font-bold'>Know More</p3>
                  <Link to="/mobiledevelopment">
                    <button>
                      <i class="fa-solid fa-chevron-right"></i>
                    </button>
                  </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 col-md-4 my-2" data-aos="fade-up" data-aos-delay="300">
              <div className='servicecard'>
                <div className='cardicon'>
                  <i class="fa-solid fa-money-bill-trend-up"></i>
                </div>
                <div className='cardContent '>
                  <h5 className='font-bold'>MVP Development</h5>
                  <p>We embrace the philosophy of MVP (Minimum Viable Product) development as a strategic approach to deliver rapid and impact solutions. Our MVP development endeavors are a commitment to efficiently bring innovative software concepts to market, empowering our clients to test ideas, gather user feedback, and iterate for continuous improvement.</p>
                  <div>
                  <p3 className='font-bold'>Know More</p3>
                  <Link to="/mvpdevelopment">
                    <button>
                      <i class="fa-solid fa-chevron-right"></i>
                    </button>
                  </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-12 col-md-6 my-2" data-aos="fade-up" data-aos-delay="300">
              <div className='servicecard'>
                <div className='cardicon'>
                  <i class="fa-solid fa-gamepad"></i>
                </div>
                <div className='cardContent '>
                  <h5 className='font-bold'>Quality Analysis</h5>
                  <p>Platform independant business solutions for maximum availability</p>
                  <Link to="/qualityanalysis">
                    <button>
                      <i class="fa-solid fa-chevron-right"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className='container'>
        <div className='client-content'>
          <div className='client-3' data-aos="fade-up" data-aos-delay="300">
            <div className='row'>
              <div className='col-lg-6'>
                <div className='content-head'>
                  <h5 className='hr-lines font-bold'>what we do</h5>
                </div>
              </div>
            </div>
            <h1 className='font-bold'>Perfect Solution for Your Business</h1>
            <p className='hm-pr-w2'>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
            </p>
          </div>
        </div>
      </div> */}
      {/* <div className='cards'>
        <div className='container'>
          <div className='row justify-content-center'>
            <Card2 icon={Stats} color="#FFE9D6" data="Grow Your Business" />
            <Card2 icon={Discount} color="#EAFFE7" data="Drive more sales" />
            <Card2 icon={Rating} color="#FFFCE0" data="Handle by Experts" />
          </div>
        </div>
      </div> */}
      {/* <div className='container'>
        <div className='row'>
          <div className='client-4 col-lg-6  col-12'>
            <div className='container' data-aos="fade-down" data-aos-delay="300">
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='content-head'>
                    <h5 className='hr-lines font-bold'>our works</h5>
                  </div>
                </div>
              </div>
              <h2 className='font-bold'>Here are few our recent Works</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
              </p>
            </div>
            <Card3 icon={Fooddelivery} heading="Food Delivery App" />
            <Card3 icon={Podcast} heading="Podcast App" />
          </div>
          <div className='card-3-2 col-lg-6 col-12'>
            <Card3 icon={Epay} heading="E-Pay App" />
            <Card3 icon={Service} heading="Service Provider App" />
            <Link to="/Products" onClick={() => window.scrollTo(0, 0)} data-aos="fade-up" data-aos-delay="300">
              <button className='contact-btn'>
                See More
                <span className='rightarrow'>
                  <i class="fa-solid fa-arrow-right"></i>
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div> */}
      <div>
        <Carousal2 />
      </div>
      <div className='container cards4 mb-3'>
        <div className='row'>
          <div className='client col-md-8' data-aos="fade-up" data-aos-delay="300">
            <div className='row'>
              <div className='col-lg-12'>
                <div className='content-head'>
                  <h5 className='hr-lines font-bold'>about us</h5>
                </div>
              </div>
            </div>
            {/* <h1 className='font-bold'>Optune Technologies</h1> */}
            <p className='hm-pr-w'>
            We provide IT based or enabled products and services focused on consumers and enterprises. We are thrilled with the excitement of customer delight when we make a consumer happy with an awesome product and gratified when we help enterprises navigate the challenges inherent in today’s business.
            </p>
          </div>
          <div className='col-md-4 aboutbtn'>
            <Link to="/Aboutus" onClick={() => window.scrollTo(0, 0)} data-aos="fade-up" data-aos-delay="300">
              <button className='contact-btn'>
                Read More
                <span className='rightarrow'>
                  <i class="fa-solid fa-arrow-right"></i>
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='team'>
            <img src={Team2} />
            <div data-aos="fade-up" data-aos-delay="300">
              <h4 className='font-bold'>Optune Technologies</h4>
              <p>
                When IT is everything and anything that is and which could be, the sheer scale of it's manifestation is a challenge to any society or community be it commercial, social or personal. The complexity can only be managed by a combination of judgment and a rule based system. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='client col-lg-8 col-md-8' data-aos="fade-up" data-aos-delay="300">
            <div className='row'>
              <div className='col-lg-12'>
                <div className='content-head'>
                  <h5 className='hr-lines font-bold'>Blogs</h5>
                </div>
              </div>
            </div>
            <h1 className='font-bold'>Our Latest Blogs</h1>
            <p className='hm-pr-w'>
             Work in progress
            </p>
          </div>
          {/*<div className='col-lg-4 col-md-4 aboutbtn' data-aos="fade-up" data-aos-delay="300">
            <Link to="/Blog" onClick={() => window.scrollTo(0, 0)} >
              <button className='contact-btn'>
                View More
                <span className='rightarrow'>
                  <i class="fa-solid fa-arrow-right"></i>
                </span>
              </button>
            </Link>
    </div>*/}
        </div>
      </div>
      <div className='container cards4 mb-5'>
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-12' data-aos="fade-up" data-aos-delay="300">
            <Card4 icon={Ecom1} />
          </div>
          <div className='col-lg-3 col-md-6 col-12' data-aos="fade-up" data-aos-delay="300">
            <Card4 icon={Ecom2} />
          </div>
          <div className='col-lg-3 col-md-6 col-12' data-aos="fade-up" data-aos-delay="300">
            <Card4 icon={Ecom3} />
          </div>
          <div className='col-lg-3 col-md-6 col-12' data-aos="fade-up" data-aos-delay="300">
            <Card4 icon={Ecom4} />
          </div>
        </div>
      </div>
    </div>
  )
}
