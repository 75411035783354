import React from 'react'
import Mvp from '../../assets/images/mvp11.png'
import M from '../../assets/images/mobiledev.png'

const Mobiledev = () => {
    return (
        <div className='mvpcontent'>
            <div className='mvpbanner'>
                <img src={Mvp} alt='banner' className='w-100 bannr-img' />
                <div className='mvpcontentx'>
                    <p className='h1'>Mobile Application Development</p>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2 mt-4'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>1</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>iOS App Development</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Detail your proficiency in developing custom iOS applications.</li>
                                    <li className='mb-4'>Highlight experience with Swift, Objective-C, and adherence to Apple's guidelines.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>2</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Android App Development</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Outline your expertise in Android app development using Java or Kotlin.</li>
                                    <li className='mb-4'>Emphasize your knowledge of the Android ecosystem and design principles.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>3</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Cross-Platform Development</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Showcase your capabilities in cross-platform development frameworks like React Native, Flutter, or Xamarin.</li>
                                    <li className='mb-4'>Explain the benefits of cross-platform development, such as cost-effectiveness and faster time-to-market.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>4</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>UI/UX Design</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'> Highlight your focus on creating visually appealing and user-friendly interfaces.</li>
                                    <li className='mb-4'>Discuss how your design process enhances the overall user experience.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>5</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>App Maintenance and Support</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Describe your post-launch services, including app maintenance, updates, and ongoing support.</li>
                                    <li className='mb-4'>Communicate your commitment to ensuring the longevity and reliability of the apps you develop.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>6</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Technologies</p>
                                <p className='intro h5 graytext font-bold'>Technology Stack:</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Provide a list of the technologies, languages, and frameworks you commonly use for mobile app development.</li>
                                    <li className='mb-4'>Showcase your alignment with industry standards and emerging technologies.</li>
                                </ul>
                                <p className='intro h5 graytext font-bold'>Innovation and Trends:</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Showcase your dedication to staying abreast of the latest mobile app development trends.</li>
                                    <li className='mb-4'>Mention any innovative solutions or practices your team employs.</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>7</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Process</p>
                                <p className='intro h5 font-bold graytext'>Development Lifecycle:</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Walk visitors through your mobile app development process, from concept and design to testing and deployment.</li>
                                    <li className='mb-4'>Emphasize client collaboration and transparent communication.</li>
                                </ul>
                                <p className='intro h5 font-bold graytext'>Testing and Quality Assurance:</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Highlight your rigorous testing and quality assurance processes to ensure bug-free and reliable mobile applications. </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>8</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Portfolio</p>
                                <p className='intro h5 font-bold graytext'>Showcase Projects:</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Display a diverse portfolio featuring a range of mobile applications you've developed.</li>
                                    <li className='mb-4'>Include project details, challenges overcome, and positive outcomes.</li>
                                </ul>
                                <p className='intro h5 font-bold graytext'>Blog/Resource Section (Educational Content) :</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Share informative articles, case studies, or industry insights related to mobile app development.</li>
                                    <li className='mb-4'>Establish your company as a thought leader in the mobile app development space.</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-md-6 order-md-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-md-6 order-md-1 ' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>9</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Testimonials</p>
                                <p className='intro h5 font-bold graytext'>Client Testimonials: </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Showcase testimonials from satisfied clients specifically related to your mobile app development services.</li>
                                    <li className='mb-4'>Highlight the impact your mobile apps have had on their business.</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mobiledev